* {
  box-sizing: border-box; }

body {
  background-color: pink;
  font-family: 'DM Serif Display', serif;
  font-size: 20px;
  background-size: 300.3px 200px;
  color: black;
  animation: bgslide 60s linear infinite; }

.contentwrapper {
  transition: transform 0.3s ease;
  padding-left: 20px;
  padding-right: 20px; }

.headline1 {
  color: pink;
  position: relative;
  display: block;
  font-size: 15.7vw;
  line-height: 10vw;
  margin-bottom: 62vw;
  z-index: 1;
  pointer-events: none;
  white-space: no-break;
  text-align: center;
  margin-top: 0.3em; }

.headline2 {
  color: pink;
  position: relative;
  display: block;
  font-size: 19vw;
  z-index: 1;
  pointer-events: none;
  line-height: 0.7em;
  white-space: no-break;
  text-align: center;
  margin-bottom: 0.75em; }

.paragraph {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.3em;
  background-color: #fff;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.4em;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  width: 100%; }

.decisionwrapper {
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease;
  counter-increment: decision-counter; }
  .decisionwrapper.js-deactivated {
    pointer-events: none;
    opacity: 0.3; }
  .decisionwrapper::after {
    content: "";
    clear: both;
    display: table; }
  .decisionwrapper::before {
    position: absolute;
    right: -0.7em;
    top: -0.7em;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    border: 1px solid black;
    text-align: center;
    line-height: 1.3em;
    background-color: pink;
    content: counter(decision-counter); }

.killscreen {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: pink;
  padding-left: 10px;
  padding-right: 10px;
  display: none; }
  .killscreen .killscreen--image {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 250px;
    height: auto;
    max-width: 100%;
    transform: translateX(-50%) translateY(-50%); }
  .killscreen .killscreen--yolo {
    position: absolute;
    right: 10px;
    bottom: -3px;
    width: 80px; }
  .killscreen .killscreen--answer {
    display: block;
    font-size: 1rem;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%; }
    .killscreen .killscreen--answer::before {
      content: 'wrong answer: '; }
  .killscreen.js-active {
    display: block; }

.winscreen {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: pink;
  padding-left: 10px;
  padding-right: 10px;
  display: none; }
  .winscreen .winscreen--image {
    width: 40px;
    display: block;
    margin: 0 auto; }
  .winscreen .winscreen--text {
    display: block;
    font-size: 2rem;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; }
  .winscreen.js-active {
    display: block; }

.decision {
  font-family: inherit;
  font-size: 1.5rem;
  line-height: 1.3em;
  display: block;
  -webkit-appearance: none;
  border-radius: 0px;
  width: 50%;
  background-color: pink;
  border: none;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  outline: none;
  height: 2em;
  float: left;
  cursor: pointer;
  margin-bottom: 10px; }
  .decision:first-of-type {
    border-left: 1px solid black; }
  .decision.js-active {
    background-color: black;
    color: pink; }

.decision-full {
  width: 100%; }

.decisionfield {
  font-family: inherit;
  font-size: 1.5rem;
  line-height: 1.3em;
  display: block;
  -webkit-appearance: none;
  border-radius: 0px;
  width: 100%;
  border: none;
  outline: none;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.4em; }
  .decisionfield::placeholder {
    opacity: 0.2; }

.balloon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 66vw;
  height: 66vw;
  cursor: grab;
  will-change: transform; }
  .balloon:active {
    cursor: grabbing; }

.red {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 43.956vw;
  height: 66vw; }

.blue {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 41.118vw;
  height: 66vw; }

body {
  background-image: url(public/pattern.png); }

.red {
  background-image: url(public/3.png); }

.blue {
  background-image: url(public/0.png); }

@keyframes bgslide {
  from {
    background-position: 0 0px; }
  to {
    background-position: 0 200px; } }
